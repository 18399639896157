const zones = {

  readyFn: function () {
    let self = this;
    self.init();
  },

  init: function () {
    $("#zones-wrapper .zone .title a").on('click', function (e) {
      e.preventDefault;
      const zone = $(this).data("zone");
      console.log(zone);
      if (zone != "") { console.log(zone);
        zones.setCookie("zone", zone, 365);
        window.location.href = "/" + zone + "/";
      } else { console.log("no zone");
        document.cookie = "zone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = "/";
      }
    });
  },

  setCookie: function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

};

export { zones };
