// IMPORT VENDOR
import $ from "jquery";
window.$ = window.jQuery = $;

import { Foundation } from "foundation-sites/js/foundation.core";
import { Reveal } from "foundation-sites/js/foundation.reveal";
Foundation.addToJquery($);
Foundation.plugin(Reveal, "Reveal");

import AOS from "aos";
import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

// IMPORT DES COMPONENTS
import { utils } from "./components/_utils";
import { recipesSlider } from "./components/_recipes-slider";
import { zones } from "./components/_zones";
import { postStarRating } from "./components/_post-star_rating";
import { postPending } from "./components/_tool-post-pending";

// IMPORT DES PAGES
//import { home } from './pages/home';
import { contact } from "./pages/contact";

// ----------------------------------- DOCUMENT READY -----------------------------------
// --------------------------------------------------------------------------------------
jQuery(function () {
  $(document).foundation();

  AOS.init();

  zones.readyFn();

  // Désactiver le click droit sur les images
  $(document).on("contextmenu", "img", function () {
    return false;
  });

  if ($(".swiper-recipes").length) {
    recipesSlider.readyFn();
  }

  if ($(".post-star-rating").length) {
    postStarRating.readyFn();
  }

  if ($("a.post-pending").length) {
    postPending.readyFn();
  }

  let bodyClassList = document.getElementsByTagName("body")[0].classList;
  switch (true) {
    // case bodyClassList.contains("template-page-formations"):
    //   formations.readyFn();
    //   break;
    case bodyClassList.contains("template-page-contact"):
      contact.readyFn();
      break;
  }

  //sessionStorage.setItem('AnnouncementOnce', 'false');
  if (sessionStorage.getItem('AnnouncementOnce') !== 'true') {
    $('#xpopup').foundation('open');
    console.log("yo ?");
    sessionStorage.setItem('AnnouncementOnce', 'true');
  }

});

// ----------------------------------- WINDOW LOAD --------------------------------------
// --------------------------------------------------------------------------------------
$(window).on("load", function () {
  $("#wide-nav-toggle").click(function (e) {
    e.preventDefault();
    $("header nav#wide-menu-wrapper").toggleClass("open");
    $(this).toggleClass("active");
    if ($("header nav#wide-menu-wrapper").hasClass("open")) {
      $("header").removeClass("white");
    } else {
      var yPos = window.pageYOffset;
      if (yPos > 100 || !$("body").hasClass("with-cover")) {
        $("header").addClass("white");
      }
    }
  });

  $("header nav#wide-menu-wrapper a").click(function (e) {
    $("header nav#wide-menu-wrapper").toggleClass("open");
  });

  $("#mobile-nav-toggle").click(function (e) {
    e.preventDefault();
    $("nav#mobile-menu-wrapper").toggleClass("open");
    $(this).find(".nav-toggle").toggleClass("active");
  });

  $("nav#mobile-menu-wrapper a").click(function (e) {
    $("nav#mobile-menu-wrapper").toggleClass("open");
  });

  $(".zones-toggle").on('click', function (e) {
    e.preventDefault();
    $("header nav#zones-wrapper").toggleClass("open");
    $("#wide-zones-close").toggleClass("active");
  });

  $("#wide-zones-close").click(function (e) {
    $("header nav#zones-wrapper").toggleClass("open");
    $(this).toggleClass("active");
  });

  $(".search-toggle").click(function (e) {
    e.preventDefault();
    $("header nav#search-wrapper").toggleClass("open");
    $("#wide-search-close").toggleClass("active");
  });
  $("#wide-search-close").click(function (e) {
    $("header nav#search-wrapper").toggleClass("open");
    $(this).toggleClass("active");
  });

  $("nav#search-wrapper a.close-button").click(function (e) {
    $("header nav#search-wrapper").toggleClass("open");
  });

  $(document).on("keydown", function (e) {
    if (e.keyCode === 27) {
      // ESC
      $(".hide-on-esc.open").removeClass("open");
      if ($("#wide-nav-toggle").hasClass("active")) {
        $("#wide-nav-toggle").removeClass("active");
      }
      if ($("#wide-zones-close").hasClass("active")) {
        $("#wide-zones-close").removeClass("active");
      }
      if ($("#wide-search-close").hasClass("active")) {
        $("#wide-search-close").removeClass("active");
      }
    }
  });

  scrollFunction();

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    const top = 100;
    var yPos = window.scrollY;
    if ($("body").hasClass("with-cover")) {
      if (yPos > top) {
        $("header").addClass("white smaller");
      } else {
        $("header").removeClass("white smaller");
      }
    } else {
      if (
        $("#wide-menu-wrapper").hasClass("open") ||
        $("#zones-wrapper").hasClass("open") ||
        $("#search-menu-wrapper").hasClass("open")
      ) {
        $("header").removeClass("white");
      } else {
        $("header").addClass("white");
      }
      if (yPos > top) {
        $("header").addClass("smaller");
      } else {
        $("header").removeClass("smaller");
      }
    }
  }

  if ($("body#accueil .cover .swiper .swiper-slide").length > 1) {
    var coverSwiper = new Swiper(".cover .swiper", {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".cover .swiper .swiper-pagination",
        clickable: true,
      },
    });
  } else {
    $(".cover").addClass("no-swipe");
  }

  if ($("body#single-etablissement .cover .swiper .swiper-slide").length > 1) {
    var coverSwiper = new Swiper(".cover .swiper", {
      loop: true,
      slidesPerView: 3,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    });
  } else {
    $(".cover").addClass("no-swipe");
  }

  // var moSwiper = new Swiper(".swiper-mobile-only", {
  //   loop: false,
  //   slidesPerView: 1.2,
  //   spaceBetween: 30,
  //   navigation: {
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev",
  //   },
  // });

  if ($("#grand-format .swiper .swiper-slide").length > 1) {
    var gfSwiper = new Swiper("#grand-format .swiper", {
      loop: false,
      slidesPerView: 3,
      spaceBetween: 30,
      navigation: {
        nextEl: ".gf-next",
        prevEl: ".gf-prev",
      },
    });
  }

  if ($("#grand-format-small .swiper .swiper-slide").length > 1) {
    var gfSwiper = new Swiper("#grand-format-small .swiper", {
      loop: true,
      slidesPerView: 1.3,
      spaceBetween: 30,
      pagination: {
        el: "#grand-format-small .swiper .swiper-pagination",
        clickable: true,
      },
    });
  }

  var postSwiper = new Swiper(".swiper-in-post", {
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  var rcSwiper = new Swiper(".right-column .swiper", {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      el: ".right-column .swiper .swiper-pagination",
      clickable: true,
    },
  });

  let bodyClassList = document.getElementsByTagName("body")[0].classList;
  switch (true) {
    case bodyClassList.contains("home"):
      home.loadFn();
      break;
  }

  $(document).foundation();
});

// ----------------------------------- WINDOW RESIZE ------------------------------------
// --------------------------------------------------------------------------------------
$(window).on("resize", function () { });
