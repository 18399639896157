// IMPORT VENDOR
// IMPORT COMPONENTS
/*
* Chemin relatif au fichier dans lequel on est
* Ex : import {utils} from '../components/_utils';
*/

const contact = {

    readyFn: function () {
        let self = this;
        self.sendForm();
    },

    sendForm: function () {
        $("form#contact-form").on('submit', function (e) {
            e.preventDefault();
            $('#modal-loading').foundation('open');
            var formData = new FormData(this);
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                data: formData,
                success: function (data) {
                    $('#modal-loading').foundation('close');
                    $('#modal-confirm').find(".message").html(data);
                    $('#modal-confirm').foundation('open');
                    $('form#contact-form').trigger('reset');
                },
                cache: false,
                contentType: false,
                processData: false
            });
        });
    }
}

export { contact };