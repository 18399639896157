const postStarRating = {

    readyFn: function () {
        let self = this;
        self.starRate();
    },

    starRate: function () {
        $(".post-star-rating .star").on("click", function (e) {
            var post_id = $(this).data("post-id"),
                rating = $(this).data("rating");
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                data: {
                    action: "star_rate",
                    post_id: post_id,
                    rating: rating
                },
                success: function (data) {
                    $(".post-star-rating").replaceWith(data);
                },
                error: function () {
                    console.log('error');
                }
            });
        });
    }
}

export { postStarRating };