import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

const recipesSlider = {
  readyFn: function () {
    let self = this;
    self.init();
    self.resize();
  },

  init: function () {
    const swiperRecipes = new Swiper(".swiper-recipes", {
      modules: [Navigation],
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: 1.2,
      spaceBetween: 30,
      breakpoints: {
        800: {
          slidesPerView: 2.5,
          spaceBetween: 50,
        },
        1200: {
          slidesPerView: 3.5,
          spaceBetween: 50,
        },
      },
      freeMode: false,
    });
  },

  resize: function () {
    var vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ),
      offset = $(".swiper-recipes").offset();
    $(".swiper-recipes").width(vw - offset.left);
  },
};

export { recipesSlider };
